/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// const stripePromise = loadStripe('pk_test_51IBneZJ0x6crg48kRhtGZVHPduV04ViBJJgeuul85EP8cnP5TL5JT6slOmBvAu163BTAHHQaoetpnnbNckKy9MB100o9ERe8L4');
const stripePromise = loadStripe('pk_live_51IBneZJ0x6crg48khvmraNQaytKRQnYDleY6p1ZaZJF7a499UnkYWzPXWHkYxrb4h4rFnKRUhDNoPs9lJh7EiPul00uiJyBzCy');

export const wrapRootElement = ({ element }) => {
  return (
    <Elements stripe={stripePromise}>
      {element}
    </Elements>
  )
}
